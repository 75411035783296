@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
@layer base {
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    @apply text-text bg-background;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply mb-2 font-bold text-primary;
  }

  p {
    @apply mb-4;
  }

  a {
    @apply text-secondary no-underline transition-colors duration-300;
  }

  a:hover {
    @apply text-secondary-dark;
  }

  button {
    @apply cursor-pointer transition-colors duration-300;
  }
}

@layer components {
  .container {
    @apply w-full max-w-6xl mx-auto px-4;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-2xl;
  }
}